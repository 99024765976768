import React from "react"
import i18next from '../i18n/config';


import { Link, Trans, useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import ImgCoffeeCup from "../img/D_kawa_ziarnista_18/ziarnista_czarka@0,5x.png";
import ImgCoffeeCupBig from "../img/D_kawa_ziarnista_18/ziarnista_czarka.png";

import ImgCoffeeArab from "../img/D_kawa_ziarnista_18/iko_arabica.png";
import ImgCoffeeRob from "../img/D_kawa_ziarnista_18/iko_robusta.png";
import ImgCoffeeBlend from "../img/D_kawa_ziarnista_18/iko_blend.png";

import ImgOurCoffeeBack from "../img/D_kawa_ziarnista_18/iko_powrot_nasze_kawy.png";
import ImgInstantCoffeeBack from "../img/D_kawa_ziarnista_18/iko_rozpuszczalna.png";
import ImgGroundCoffeeBack from "../img/D_kawa_ziarnista_18/iko_mielona.png";

export default function Header(props) {



    return (
            <React.Fragment>
                <section class="coffeebeans firstSection">
                    <h1>{i18next.t('CoffeeBeansH1')}</h1>
                    <p>{i18next.t('CoffeeBeansDesc')}</p>
                    <div class="items">

                        <div class="item">
                            <div> <img loading="lazy" src={ImgCoffeeArab} alt="Instanta Arabica Coffee" /></div>
                            <div>
                                <h2>{i18next.t('CoffeeBeansArab')}</h2>
                                <p>{i18next.t('CoffeeBeansArabDsc')}</p>

                            </div>
                        </div>
                        <div class="item">
                            <div><img loading="lazy" src={ImgCoffeeRob} alt="Instanta Robusta Coffee" /></div>
                            <div>
                                <h2>{i18next.t('CoffeeBeansRob')}</h2>
                                <p>{i18next.t('CoffeeBeansRobDsc')}</p>
                            </div>
                        </div>
                        <div class="item">
                            <div><img loading="lazy" src={ImgCoffeeBlend} alt="Instanta Blend Coffee" /></div>
                            <div>
                                <h2>{i18next.t('CoffeeBeansBlend')}</h2>
                                <p>{i18next.t('CoffeeBeansBlendDsc')}</p>
                            </div>
                        </div>


                    </div>

                    <div class="links">
                        <div class="link">
                            <Link to={'/OurPackaging/Packaging'}>
                            <p>{i18next.t('CoffeeBeansLink1')}</p>
                            </Link></div>
                        <div class="link">
                            <Link to={'/Collaboration/'}>
                            <p>{i18next.t('CoffeeBeansLink2')}</p>
                            </Link></div>
                        <div class="link">
                            <Link to={'/Quality/'}>
                            <p>{i18next.t('CoffeeBeansLink3')}</p>
                            </Link></div>
                        <div class="link">
                            <Link to={'/OurPackaging/Design'}>
                            <p>{i18next.t('CoffeeBeansLink4')}</p>
                            </Link></div>
                    </div>

                    <div class="cup"><img loading="lazy" src={ImgCoffeeCup} alt="Instanta Coffee Cup" />
                    </div>
                    <aside class="mininavi">
                        <div>
                            <Link to={'/OurCoffees/'}>
                            <img loading="lazy" src={ImgOurCoffeeBack} alt="Instanta Coffee Back Icon" />
                            <p>{i18next.t('CoffeeBeansMiniMenu1')}</p>
                            </Link>
                        </div>
                        <div>
                            <Link to={'/OurCoffees/InstantCoffee'}>
                            <img loading="lazy" src={ImgInstantCoffeeBack} alt="Instant Coffee Icon" />
                            <p>{i18next.t('CoffeeBeansMiniMenu2')}</p>
                            </Link>
                        </div>   <div>
                            <Link to={'/OurCoffees/GroundCoffee'}>
                            <img loading="lazy" src={ImgGroundCoffeeBack} alt="Instanta Ground Coffee Icon" />
                            <p>{i18next.t('CoffeeBeansMiniMenu3')}</p>
                            </Link>
                        </div>
                    </aside>
                </section>


            </React.Fragment>

            )


}